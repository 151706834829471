@include block("status-checker") {
  $this-block: "status-checker";
  @extend .feature;
  grid-area: Track-Existing-Claim;
  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    margin: auto;
    vertical-align: top;
    padding-top: 10px;
  }
  @media only all and (-ms-high-contrast: none) and (min-width: 62.5em) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    vertical-align: top;
    height: auto;
    -ms-grid-row-align: start;
  }
  margin-bottom: 0;
  margin-top: 0;
  align-self: flex-start;
  max-width: calc(46rem + 2em);
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 62.5em) {
    margin-top: 1.5rem;
  }

  &__heading {
    @extend h3;
  }

  &__ticket-label {
    @extend h3;
  }

  @include element("error-message") {
    @include applyThematically(color, VA_ERROR_MESSAGE_TEXT_COLOR);
    font-weight: 700;
  }
  // @include element("tracking-code-input") {
  //   @extend .usa-input !optional;
  // }
}
