.layout-sidebar {
  @extend .row;
  @extend .layout-main-container;
  position: relative;

  // @media only screen and (min-width: $metric__break-sm) {
  //   // min-height: 0;
  //   height: calc(100% - 3rem);
  // }
  // @media only screen and (min-width: $metric__break-md) {
  //   // min-height: 0;
  //   height: calc(100% - 6rem);
  // }

  &--inherit-height {
    height: auto;
  }

  &--flex-fill {
    height: auto;
    flex: 1;
  }
  //   margin-top: $metric__nav-height;

  &__sidebar {
    @extend .col-xs-12;
    @extend .col-sm-5;
    @extend .col-md-3;
    @extend .col-lg-3;
    @extend .col-xl-2;
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;

    @media only screen and (max-width: $metric__break-md) {
      height: 100%;
    }
    // @media only screen and (max-width: $metric__break-sm) {
    //   height: inherit;
    // }

    &--inherit-height {
      height: auto;
    }

    &--floating {
      overflow-x: none;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0px; /* remove scrollbar space */
        background: transparent; /* optional: just make scrollbar invisible */
      }
    }

    &--wide {
      @extend .col-xs-12;
      @extend .col-sm-7;
      @extend .col-md-4;
      @extend .col-lg-3;
      @extend .col-xl-3;
    }
    &--remove-pad {
      padding-left: 0;
      padding-right: 0;
    }
    &--sm-overlay {
      @media only screen and (max-width: $metric__break-sm) {
        background-color: $PRIMARY_CONTENT_BACKGROUND_COLOR;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
  &__main {
    @extend .col-xs-12;
    @extend .col-md-6;
    &--md-overlay {
      @media only screen and (max-width: $metric__break-md) {
        background-color: $PRIMARY_CONTENT_BACKGROUND_COLOR;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
