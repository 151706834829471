@include block("form-component") {
  .feature {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 46rem;
  }
  &__subtitle {
    margin-bottom: 1rem;
  }
  &__confirmation {
    border-top: 1px solid transparent;
    @include applyThematically(border-top-color, VA_PAGE_TEXT_COLOR);
    padding-top: 1.5rem;
    margin-top: 1rem;
    max-width: 46rem;
  }
  &__submit-button {
    margin-top: 0.75rem;
  }
}
