@charset "UTF-8";

// Vendor Libraries
@import "libraries/reset__com-meyerweb--2-0-0";
@import "libraries/grid__com-flexboxgrid--6-3-2";
@import "libraries/animate_git-daneden--3-7-0";

// VA Formation
$formation-asset-path: "~@department-of-veterans-affairs/formation/assets";
@import "~@department-of-veterans-affairs/formation/sass/full";
// @import "~@department-of-veterans-affairs/formation/sass/site/m-footer";
// @import "~@department-of-veterans-affairs/formation/sass/site/m-vet-nav";

// Utilities
// functions and variables
@import "utilities/bem-functions";
@import "utilities/breakpoints";
@import "utilities/colors";
@import "utilities/materials";
@import "utilities/custom-animations";
// reusable classes
@import "utilities/modifiers";
@import "utilities/typography";

// Base Styling -- applies to all pages
@import "base/base";

// Views
@import "../components/App/App";

// rBEM (Reactive BEM) Patterns
@import "blocks/patterns/button";
@import "blocks/patterns/form";
@import "blocks/patterns/tooltip";
@import "blocks/patterns/graph";

// Pages
@import "pages/404-page";

@import "pages/landing-page";
@import "pages/upload-page";

// rBEM (Reactive BEM) Blocks
@import "blocks/claim-status-card";
@import "blocks/cms";
@import "blocks/form-component";
@import "blocks/page-footer";
@import "blocks/page-introduction";
@import "blocks/site-header";
@import "blocks/status-checker";
@import "blocks/submit-new-document";
@import "blocks/tos";
@import "blocks/veterans-affairs-breadcrumbs";
@import "blocks/veterans-affairs-footer";
@import "blocks/veterans-affairs-header";

// Page Layouts
@import "layouts/positioning";
@import "layouts/layout-utilities";
@import "layouts/layout-main-container";
@import "layouts/layout-toolbar";
@import "layouts/layout-sidebar";
@import "layouts/master-detail-layout";

body {
  @include applyThematically(background-color, VA_PAGE_BACKGROUND_COLOR);
  @include applyThematically(color, VA_PAGE_TEXT_COLOR);
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @include applyThematically(color, VA_PAGE_HEADING_COLOR);
}
.feature {
  @include applyThematically(background-color, VA_FEATURE_BACKGROUND_COLOR);
}
.feature--confirmation {
  @include applyThematically(
    background-color,
    VA_FEATURE_BACKGROUND_COLOR--CONFIRMATION
  );
}
input {
  @include applyThematically(background-color, VA_PAGE_BACKGROUND_COLOR);
  @include applyThematically(color, VA_PAGE_TEXT_COLOR);
  @include applyThematically(border-color, VA_INPUT_BORDER_COLOR);
}
a,
a:visited {
  @include applyThematically(color, VA_LINK_COLOR);
}
button {
  // @include applyThematically(background-color, VA_BUTTON_BACKGROUND);
  // @include applyThematically(color, VA_BUTTON_TEXT);
  &:hover {
    @include applyThematically(
      background-color,
      VA_PRIMARY_BUTTON_BACKGROUND_COLOR--HOVER
    );
    // background-color: VA_PRIMARY_BUTTON_BACKGROUND_COLOR--HOVER;
  }
  &:active {
    @include applyThematically(
      background-color,
      VA_PRIMARY_BUTTON_BACKGROUND_COLOR--ACTIVE
    );
  }
}
.usa-button:disabled {
  @include applyThematically(
    background-color,
    VA_DISABLED_BUTTON_BACKGROUND_COLOR
  );
  @include applyThematically(color, VA_DISABLED_BUTTON_TEXT_COLOR);
}
.usa-button.usa-button-secondary {
  @include darkMode() {
    box-shadow: inset 0 0 0 2px
      getThematicValue(VA_LINK_COLOR, dark);
    color: getThematicValue(VA_LINK_COLOR, dark);
  }
  &:hover {
    @include darkMode() {
      box-shadow: inset 0 0 0 2px
        getThematicValue(VA_PRIMARY_BUTTON_BACKGROUND_COLOR--HOVER, dark);
      color: getThematicValue(VA_PRIMARY_BUTTON_BACKGROUND_COLOR--HOVER, dark);
    }
  }
  &:active {
    @include darkMode() {
      box-shadow: inset 0 0 0 2px
        getThematicValue(VA_PRIMARY_BUTTON_BACKGROUND_COLOR--ACTIVE, dark);
      color: getThematicValue(VA_PRIMARY_BUTTON_BACKGROUND_COLOR--ACTIVE, dark);
    }
  }
}
.process-step {
  @include applyThematically(border-left-color, VA_PROCESS_STEP_COLOR);
}
.process-step:before {
  @include applyThematically(border-color, VA_PAGE_BACKGROUND_COLOR);
  @include applyThematically(background-color, VA_PROCESS_STEP_COLOR);
}
.process-step--active {
  @include applyThematically(border-left-color, VA_PROCESS_STEP_ACTIVE_COLOR);
}
.process-step--active:before {
  @include applyThematically(background-color, VA_PROCESS_STEP_ACTIVE_COLOR);
}

input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
button:not([disabled]):focus {
  @include applyThematically(outline-color, VA_INPUT_OUTLINE_COLOR);
}

.fa {
  padding-left: 0 !important;
}

.list-check {
  border-left-color: transparent;
  padding-bottom: 0;
}
.list-check:before {
  content: "✔";
  @include applyThematically(background-color, VA_PROCESS_STEP_COMPLETE_COLOR);
  // background-color: #2E8540;
}
.second-offset-ol {
  padding-left: 0;

  h3 {
    margin-top: 0;
  }
}

.usa-alert {
  @include applyThematically(background-color, VA_ALERT_BACKGROUND_COLOR);
}
.usa-alert-success {
  @include applyThematically(border-left-color, VA_PROCESS_STEP_COMPLETE_COLOR);
  &::before {
    @include applyThematically(color, VA_PROCESS_STEP_COMPLETE_COLOR);
  }
}

.va-nav-breadcrumbs {
  @include applyThematically(background-color, VA_PAGE_BACKGROUND_COLOR);
  @include applyThematically(color, VA_LINK_COLOR);

  a:hover {
    @include applyThematically(background, VA_LINK_HOVER_BACKGROUND_TINT);
  }
}
