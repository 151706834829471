@include block("master-detail-layout") {
  @extend .layout-main-container;
  @extend .layout-main-container--application-header-offset;
  position: relative;
  overflow-x: hidden;
  display: flex;
  // DEBUG
  background-color: #333333;
  border: 1px solid #ff0000;
  // END DEBUG
  &__master-view {
    flex: 1;
    // DEBUG
    background-color: #0000ff;
    border: 1px solid #ff0000;
    // END DEBUG
  }
  &__detail-view {
    flex: 2.65;
    // DEBUG
    background-color: #00ff00;
    border: 1px solid #ff0000;
    // END DEBUG
  }
}
