.positioning__default-pad {
  padding: 1rem;
}

.positioning__footer-pad {
  padding: 2.387287570313157rem 1rem;
  @media #{$query__xs-above} {
    padding: 1rem 2.387287570313157rem;
  }
  @media #{$query__sm-above} {
    padding: 2rem 2.387287570313157rem;
  }
}

.positioning__large-pad {
  padding: 1rem;
  @media #{$query__xs-above} {
    padding: 2.387287570313157rem;
  }
  @media #{$query__sm-above} {
    padding: 3.8627935723rem;
  }
  @media #{$query__md-above} {
    padding: 6.25rem;
  }
}

// TODO ARH: Needed a different pad definition. I just picked a base of 4rem. Not sure if that's what we want.
.positioning__medium-pad {
  padding: 1rem;
  @media #{$query__xs-above} {
    padding: 1.527864045rem;
  }
  @media #{$query__sm-above} {
    padding: 2.472135955rem;
  }
  @media #{$query__md-above} {
    padding: 4rem;
  }
}

.positioning__wide-pad {
  padding: 1rem;
  @media #{$query__xs-above} {
    padding: 1rem 2.387287570313157rem;
  }
  @media #{$query__sm-above} {
    padding: 2.387287570313157rem 3.8627935723rem;
  }
  @media #{$query__md-above} {
    padding: 3.8627935723rem 6.25rem;
  }
}
