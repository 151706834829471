$measure__iframe--header-height-compensation: 71px;

$measure__golden-ratio: 1.61803398875;
$metric__nav-height: convertBase16ToBase10(5.5rem);
$metric__nav-height--collapsed: 2.75rem;
$metric__max-width: 120rem;
$metric__break-xs: 32rem;
$metric__break-ss: 40rem;
$metric__break-sm: 48rem;
$metric__break-md: 64rem;
$metric__break-lg: 75rem;
$metric__break-xl: 90rem;
$metric__break-xxl: 105rem;
$pct__golden-ratio-inverse: 61.803398875%;
$breakpoint--unit: "em";
$query__ss-above: "only screen and (min-width : #{$metric__break-ss}#{$breakpoint--unit})";
$query__xs-above: "only screen and (min-width : #{$metric__break-xs}#{$breakpoint--unit})";
$query__xs-below: "only screen and (min-width : #{$metric__break-xs}#{$breakpoint--unit - 0.000001})";
$query__sm-above: "only screen and (min-width : #{$metric__break-sm}#{$breakpoint--unit})";
$query__sm-below: "only screen and (max-width : #{$metric__break-sm}#{$breakpoint--unit - 0.000001})";
$query__md-above: "only screen and (min-width : #{$metric__break-md}#{$breakpoint--unit})";
$query__lg-above: "only screen and (min-width : #{$metric__break-lg}#{$breakpoint--unit})";
$query__xl-above: "only screen and (min-width : #{$metric__break-xl}#{$breakpoint--unit})";
