// Layout Prototype: Main Container
// # About
// This Layout Prototype provides the
@include block("layout-toolbar") {
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: $metric__max-width;
  position: relative;

  &--flex-fill {
    flex: 1;
    height: auto;
  }

  &__toolbar {
    @extend .--disable-select;
  }

  &__main-content {
    flex: 1;
  }
}
