@include block("site-header") {
  $this-block: "site-header";
  @extend .--APPLICATION-MATERIAL;
  z-index: 900;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;

  // visibility: hidden;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  // display: flex;
  // flex-direction: row;
  // align-items: flex-end;
  // justify-content: center;
  -webkit-overflow-scrolling: auto;
  // height: $metric__nav-height / 2;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-end;

  height: calc(#{convertBase16ToBase10(3rem)} + env(safe-area-inset-top, 0));
  font-family: $font-primary;

  // padding-top: env(safe-area-inset-top, 0);

  // background-color: #fbad35;
  // visibility: hidden;
  // @media only screen and (min-width: $metric__break-sm) {
  //   // height: $metric__nav-height;
  //   height: convertBase16ToBase10($metric__nav-height);
  //   align-items: bottom;
  // }

  // @media (orientation: landscape) {
  //   position: absolute;

  //   @media (min-width: $metric__break-md) {
  //     position: fixed;
  //   }
  // }
  &__main-content {
    grid-column: 2;
    display: grid;
    grid-template-columns: convertBase16ToBase10(1rem) auto 1fr auto convertBase16ToBase10(
        1rem
      );
    width: 100%;
    max-width: convertBase16ToBase10(75rem);
    // display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // align-self: center;
    justify-self: center;
    height: convertBase16ToBase10(2.75rem);

    margin-left: env(safe-area-inset-left);
    top: 0px;
    // margin-top: env(safe-area-inset-top);
    margin-right: env(safe-area-inset-right);
    position: relative;
  }
  &__application-name {
    @extend h5;
    grid-column: 2;
    @include applyThematically(color, VA_PAGE_HEADING_COLOR);

    // font: -apple-system-headline;
    // @include applyThematically(color, LABEL_COLOR);
    // font-family: $font-display;
    // @extend .--disable-select;
    margin-top: 0;
    margin-bottom: 0;
    // padding-left: convertBase16ToBase10(0.62rem);
    // padding-right: convertBase16ToBase10(0.62rem);
    // padding-top: convertBase16ToBase10(0.175rem);
    // transition: color 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    // display: inline;
    &:hover {
      @include applyThematically(color, VA_PAGE_HEADING_COLOR);
      background-color: transparent;
      text-decoration: none;
    }
    &:visited {
      @include applyThematically(color, VA_PAGE_HEADING_COLOR);
    }
    &:active {
      @include applyThematically(color, TERTIARY_LABEL_COLOR);
    }
  }
  &__help-link {
    grid-column: 4;
    justify-self: flex-end;
  }

  &__application-context {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: convertBase16ToBase10(3rem);
    order: 2;
    flex: 1;
    @media only screen and (min-width: $metric__break-sm) {
      flex: none;
      justify-content: space-between;
      align-items: flex-end;
      order: 1;
      border-bottom: 1px solid $PRIMARY_HEADER_CONTENT_BORDER_COLOR;
    }
  }
  &__navigation {
    flex: 1;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: $metric__break-sm) {
      flex: 1;
      padding-right: convertBase16ToBase10(1rem);
      display: flex;
      flex-direction: column;
    }
  }
  &__main-navigation-toggle {
    display: none;
    &:checked ~ .#{$this-block} {
      &__main-navigation-toggle-trigger {
        @extend .#{$this-block}__main-navigation-toggle-trigger--open;
        // .#{$this-block}__main-navigation-toggle-icon {
        //   .#{$this-block}__main-navigation-toggle-glyph {
        //     &--open {
        //       display: block;
        //     }
        //     &--closed {
        //       display: none;
        //     }
        //   }
        // }
      }
      &__main-navigation {
        display: flex;
      }
    }
  }
  // site-header__main-navigation-toggle-glyph--closed
  &__main-navigation-toggle-trigger {
    // <svg className="site-header__main-navigation-toggle-glyph--open" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    //   <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="#FFFFFF"/>
    //   <path d="M0 0h36v36H0z" fill="none"/>
    // </svg>
    height: convertBase16ToBase10(2.75rem);
    width: convertBase16ToBase10(2.75rem);
    margin-right: convertBase16ToBase10(0.03rem);
    @media only screen and (min-width: convertBase16ToBase10(22rem)) {
      margin-right: 0;
    }
    background-repeat: no-repeat;
    background-size: convertBase16ToBase10(2rem) convertBase16ToBase10(2rem);
    background-position: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml;utf8,%20%20%20%20%20%20%3Csvg%20className%3D%22site-header__main-navigation-toggle-glyph--closed%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2027h28v-3H4v3zm0-8h28v-3H4v3zM4%208v3h28V8H4z%22%20fill%3D%22#{urlFriendlyColor(getThematicValue(SECONDARY_LABEL_COLOR, light))}%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E");
    @include darkMode() {
      background-image: url("data:image/svg+xml;utf8,%20%20%20%20%20%20%3Csvg%20className%3D%22site-header__main-navigation-toggle-glyph--closed%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2027h28v-3H4v3zm0-8h28v-3H4v3zM4%208v3h28V8H4z%22%20fill%3D%22#{urlFriendlyColor(getThematicValue(SECONDARY_LABEL_COLOR, dark))}%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E");
    }
    &:active {
      background-image: url("data:image/svg+xml;utf8,%20%20%20%20%20%20%3Csvg%20className%3D%22site-header__main-navigation-toggle-glyph--closed%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2027h28v-3H4v3zm0-8h28v-3H4v3zM4%208v3h28V8H4z%22%20fill%3D%22#{urlFriendlyColor(getThematicValue(TERTIARY_LABEL_COLOR, light))}%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E");
      @include darkMode() {
        background-image: url("data:image/svg+xml;utf8,%20%20%20%20%20%20%3Csvg%20className%3D%22site-header__main-navigation-toggle-glyph--closed%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2027h28v-3H4v3zm0-8h28v-3H4v3zM4%208v3h28V8H4z%22%20fill%3D%22#{urlFriendlyColor(getThematicValue(TERTIARY_LABEL_COLOR, dark))}%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E");
      }
    }

    &--open {
      background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M28.5%209.62L26.38%207.5%2018%2015.88%209.62%207.5%207.5%209.62%2015.88%2018%207.5%2026.38l2.12%202.12L18%2020.12l8.38%208.38%202.12-2.12L20.12%2018z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
      @include darkMode() {
        background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M28.5%209.62L26.38%207.5%2018%2015.88%209.62%207.5%207.5%209.62%2015.88%2018%207.5%2026.38l2.12%202.12L18%2020.12l8.38%208.38%202.12-2.12L20.12%2018z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
      }
      &:active {
        background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M28.5%209.62L26.38%207.5%2018%2015.88%209.62%207.5%207.5%209.62%2015.88%2018%207.5%2026.38l2.12%202.12L18%2020.12l8.38%208.38%202.12-2.12L20.12%2018z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
        @include darkMode() {
          background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M28.5%209.62L26.38%207.5%2018%2015.88%209.62%207.5%207.5%209.62%2015.88%2018%207.5%2026.38l2.12%202.12L18%2020.12l8.38%208.38%202.12-2.12L20.12%2018z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200h36v36H0z%22%20fill%3D%22none%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
        }
      }
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    min-width: convertBase16ToBase10(2rem);
    align-self: center;
    @media only screen and (min-width: $metric__break-sm) {
      display: none;
    }
  }
  &__main-navigation-toggle-icon {
    width: convertBase16ToBase10(2rem);
    height: convertBase16ToBase10(2rem);
    align-self: center;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &__main-navigation-toggle-glyph {
    &--open {
      display: none;
    }
  }
  &__main-navigation {
    @extend .--APPLICATION-MATERIAL--SECONDARY;
    display: none;
    position: absolute;
    // position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-top: convertBase16ToBase10(3rem);
    align-items: stretch;
    flex-direction: column;
    max-width: convertBase16ToBase10(120rem);
    order: 1;
    @media only screen and (min-width: $metric__break-sm) {
      order: 2;
      width: 100%;
      display: flex;
      height: convertBase16ToBase10(3rem);
      flex-direction: row;
      max-width: convertBase16ToBase10(120rem);
      align-items: flex-end;
      flex-direction: row;
      position: relative;
      margin-top: 0;
      background: transparent;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
  &__navigation-link {
    @extend .--disable-select;
    font-size: 1.065em;
    @include applyThematically(color, LABEL_COLOR);
    padding: convertBase16ToBase10(0.8rem) 0 convertBase16ToBase10(0.8rem);
    border-top: 1px solid $PRIMARY_HEADER_CONTENT_BORDER_COLOR;
    margin: 0 convertBase16ToBase10(1.25rem);
    // text-shadow: 0 1px 0 $PRIMARY_HEADER_CONTENT_BORDER_COLOR;
    @media only screen and (min-width: $metric__break-sm) {
      display: inline;
      line-height: auto;
      margin-right: 0;
      margin-left: 0;
      padding-left: convertBase16ToBase10(0.5rem);
      padding-top: convertBase16ToBase10(0.25rem);
      padding-right: convertBase16ToBase10(0.75rem);
      padding-bottom: convertBase16ToBase10(0.4rem);
      border-bottom: convertBase16ToBase10(0.5rem) solid transparent;
      letter-spacing: convertBase16ToBase10(0.075rem);
      border-top: none;
      text-decoration: none;
    }
    &:visited {
      @include applyThematically(color, LABEL_COLOR);
    }
    &:hover {
      color: $PRIMARY_HEADER_LINK_TEXT_COLOR--HOVER;
      // border-bottom-color: $CONTROL_ACCENT_COLOR;
      background-color: transparent;
      text-decoration: none;
    }
  }
  &__navigation-action {
    min-width: 8em;
    & + .#{$this-block}__navigation-action {
      margin-left: 1em;
    }
  }
  &__main-logo {
    display: inline-block;
    float: left;
    width: auto;
    align-items: center;
    justify-content: center;
    a {
      margin-right: 0 !important;
    }
    &-image {
      height: convertBase16ToBase10(3.75rem);
      width: auto;
    }
    &-caption {
      display: none;
    }
  }
  &__spacer {
    position: relative;
    width: 100%;
    height: 5.875em;
    pointer-events: none;
  }
  &__active-user {
    min-width: convertBase16ToBase10(2.75rem);
    min-height: convertBase16ToBase10(2.75rem);
    z-index: 1000;
    &[open] {
      .#{$this-block}__active-user-badge {
        // @include applyThematically(border-bottom-color, APPLICATION_BACKGROUND_COLOR);
        border-bottom-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
      }
    }
    @media only screen and (max-width: $metric__break-sm) {
      // for small viewports, make the menu occupy the entire screen
      &[open] {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .#{$this-block}__active-user-wrapper {
          padding-right: convertBase16ToBase10(1rem);
          padding-left: convertBase16ToBase10(1rem);
          height: convertBase16ToBase10(3rem);
          align-items: center;
        }
        .#{$this-block}__active-user-content {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
    @media only screen and (min-width: $metric__break-sm) {
      // for large viewports, make the menu render in place
      position: relative;
    }
  }
  &__active-user-wrapper {
    min-width: convertBase16ToBase10(2.75rem);
    min-height: convertBase16ToBase10(2.75rem);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: row-reverse;
    @media only screen and (min-width: $metric__break-sm) {
      flex-direction: row;
    }
  }
  &__active-user-badge {
    @extend .--disable-select;

    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
    @media only screen and (min-width: $metric__break-sm) {
      min-width: 1convertbase16tobase10 (5rem);
      padding: convertBase16ToBase10(0.3rem) convertBase16ToBase10(0.4rem);
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 6px;
      flex-direction: row;
      text-align: left;
      @include boxShadow(1);
      @include lightMode() {
        border: 0.8px solid getThematicValue(APPLICATION_BORDER_COLOR, "light");
        background-color: getThematicValue(MODAL_BACKGROUND_COLOR, "light");
      }
      @include darkMode() {
        background-color: getThematicValue(MODAL_BACKGROUND_COLOR, "dark");
        border: 0.8px solid getThematicValue(APPLICATION_BORDER_COLOR, "dark");
      }
      &::-webkit-details-marker {
        order: 99;
        align-self: center;
        padding-right: convertBase16ToBase10(0.25rem);
        display: none;
      }
    }
  }
  &__active-user-avatar {
    width: convertBase16ToBase10(2rem);
    height: convertBase16ToBase10(2rem);
    border-radius: convertBase16ToBase10(1rem);
    align-self: center;
    box-shadow: inset 0 1px rgba(255, 255, 255, 1),
      0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    // background: linear-gradient(to bottom, #e4e6eb 0%,#DCDEE2 100%);
    background-color: $SECONDARY_CONTENT_BACKGROUND_COLOR;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    @media only screen and (min-width: $metric__break-sm) {
      margin-left: convertBase16ToBase10(0.15rem);
      margin-right: convertBase16ToBase10(0.1rem);
      width: convertBase16ToBase10(3rem);
      height: convertBase16ToBase10(3rem);
      border-radius: convertBase16ToBase10(1.5rem);
      background-color: $SECONDARY_CONTENT_BACKGROUND_COLOR;
    }
  }
  &__active-user-avatar-caption {
    font-size: convertBase16ToBase10(1.3rem);
    letter-spacing: -0.2;
    font-weight: 700;
    margin-top: 0;
    color: $QUATERNARY_LABEL_TEXT_COLOR;
  }
  &__active-user-content {
    display: none;

    @media only screen and (min-width: $metric__break-sm) {
      padding-left: convertBase16ToBase10(0.5rem);
      padding-top: convertBase16ToBase10(0.5rem);
      padding-bottom: calc(convertBase16ToBase10(0.5rem) + 4px);
      display: flex;
      flex-direction: column;
      padding-right: convertBase16ToBase10(1rem);
      flex: 1;
    }
  }
  &__active-user-menu {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: convertBase16ToBase10(3.5rem);
    padding-bottom: convertBase16ToBase10(0.4rem);
    align-items: stretch;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    @include boxShadow(1);
    @include lightMode() {
      border: 0.8px solid getThematicValue(APPLICATION_BORDER_COLOR, "light");
      background-color: getThematicValue(MODAL_BACKGROUND_COLOR, "light");
    }
    @include darkMode() {
      background-color: getThematicValue(MODAL_BACKGROUND_COLOR, "dark");
      border: 0.8px solid getThematicValue(APPLICATION_BORDER_COLOR, "dark");
    }
    @media only screen and (min-width: $metric__break-sm) {
      padding-top: convertBase16ToBase10(3.75rem);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      z-index: -1;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    &--closed {
      display: none;
    }
  }
  &__active-user-menu-option {
    @extend .--disable-select;
    padding: convertBase16ToBase10(0.75rem) 0 convertBase16ToBase10(0.65rem);
    margin: 0 convertBase16ToBase10(1rem);
    display: flex;
    // flex: 1;
    align-items: flex-end;
    font-weight: 700;
    color: $PRIMARY_LINK_TEXT_COLOR;
    border-top: 1px solid transparent;
    @include applyThematically(border-color, APPLICATION_BORDER_COLOR);
    @media only screen and (min-width: $metric__break-sm) {
      padding: convertBase16ToBase10(0.75rem) 0 convertBase16ToBase10(0.65rem);
      margin: 0 convertBase16ToBase10(0.75rem);
    }
    &:hover {
      color: $PRIMARY_LINK_TEXT_COLOR--HOVER;
    }
    &:active {
      color: $PRIMARY_LINK_TEXT_COLOR--ACTIVE;
    }
  }
  &__active-user-menu-caption {
    @extend .--disable-select;
    padding: 0 0 convertBase16ToBase10(0.65rem);
    margin: 0 convertBase16ToBase10(0.75rem);
    display: flex;
    // flex: 1;
    order: 99;
    flex-direction: row;
    align-items: stretch;
    font-weight: 700;
    color: $QUATERNARY_LABEL_TEXT_COLOR;
    font-size: convertBase16ToBase10(0.75rem);
    line-height: convertBase16ToBase10(0.95rem);
  }
  &__active-user-menu-button {
    @extend .--disable-select;
    padding: convertBase16ToBase10(0.5rem) convertBase16ToBase10(0.4rem);
    flex: 1;
    background-color: $PRIMARY_BUTTON_BACKGROUND_COLOR;
    text-shadow: 0 1px 0 $PRIMARY_BUTTON_TEXT_SHADOW_COLOR;
    margin-top: convertBase16ToBase10(0.2rem);
    margin-bottom: convertBase16ToBase10(0.1rem);
    border-radius: 5px;
    text-align: center;
    border-top: none;
    box-sizing: content-box;
    color: $PRIMARY_LINK_TEXT_COLOR;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      background-color: $PRIMARY_BUTTON_BACKGROUND_COLOR--HOVER;
    }
    &:active {
      background-color: $PRIMARY_BUTTON_BACKGROUND_COLOR--ACTIVE;
    }
    &--warning {
      color: $PRIMARY_WARNING_BUTTON_TEXT_COLOR;
      text-shadow: 0 1px 0 $PRIMARY_WARNING_BUTTON_TEXT_SHADOW_COLOR;
      background-color: $PRIMARY_WARNING_BUTTON_BACKGROUND_COLOR;
      &:hover {
        background-color: $PRIMARY_WARNING_BUTTON_BACKGROUND_COLOR--HOVER;
      }
      &:active {
        background-color: $PRIMARY_WARNING_BUTTON_BACKGROUND_COLOR--ACTIVE;
      }
    }
  }
  &__active-user-name {
    @extend .--disable-select;
    font-weight: 700;
    cursor: pointer;
    color: $PRIMARY_LABEL_TEXT_COLOR;
    @include applyThematically(color, LABEL_COLOR);
  }
  &__active-user-label {
    @extend .text-style__navigation-email;
    letter-spacing: -0.012em;
    font-weight: 700;
    color: $TERTIARY_LABEL_TEXT_COLOR;
    margin-top: 0;
  }
  &__copyright {
    margin: 0.5em;
    margin-top: 0em;
    @extend .text-style__caption1;
    font-weight: 100;
    align-self: flex-start;
  }
}
