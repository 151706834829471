// Commented out to allow VA Formation to determine default fonts
// @include import__font--google-fonts($font-primary);
// html {
//   font-size: 16px;
//   line-height: 21px;
//   font-weight: 300;
//   color: $PRIMARY_BODY_TEXT_COLOR;
//   -webkit-font-smoothing: antialiased;
// }

// body {
//   font-family: $font-primary;
//   font-size: 1em;
//   line-height: 1.4375em;
//   margin: auto;
// }

p,
ul,
ol,
pre,
table,
blockquote {
  margin-top: 0em;
  // margin-bottom: 1.4375em;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0em;
  margin-bottom: 0em;
}

/* Let's make sure all's aligned */

hr {
  border: 1px solid;
  margin: -1px 0;
}

b,
i,
strong,
em,
small,
code {
  line-height: 0;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

@media #{$query__md-above} {
  body {
    margin: auto;
  }
  p,
  ul,
  ol,
  pre,
  table,
  blockquote {
    margin-top: 0em;
    // margin-bottom: 1.3125em;
  }
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin-top: 0em;
    margin-bottom: 0em;
  }
  /* Let's make sure all's aligned */
  hr {
    border: 1px solid;
    margin: -1px 0;
  }
  a,
  b,
  i,
  strong,
  em,
  small,
  code {
    // line-height: 0;
  }
  sub,
  sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
}
