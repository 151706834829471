// TP: dont start classes with an underscore
@include block("_404-page") {
  &__container {
    display: flex;
    margin-top: 6rem * 1.6;
  }
  &__sidebars {
    flex: 1;
  }
  &__center {
    @extend .positioning__large-pad;
    flex: 4;
  }
  &__title {
    @extend .text-style__headline--large;
  }
  &__subtitle {
    // TODO ARH: According to the mockup, this is supposed to be not bolded.
    @extend .text-style__subhead;
  }
}
