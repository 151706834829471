@include block("page-introduction") {
  grid-area: Page-Introduction;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  @media only all and (-ms-high-contrast:none) and (min-width: 62.5em) {
    -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
  }
  max-width: 90rem;
  &__title {
    @extend h1;
  }
}
