@include block("veterans-affairs-header") {
  grid-area: VA-Header;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  &__site-guidance {
    padding: 0.3rem 0.5rem;
    // max-width: 100rem;
    width: 100%;
    justify-self: center;
    // #323a45!important
    @include applyThematically(background-color, VA_HEADER_SITE_GUIDANCE_BACKGROUND_COLOR--WARNING);
    // display: flex;
  }
  &__site-guidance-text {
    text-align:center;
    padding: 0 0.5rem;
    font-size: 1.5rem;
    // @include applyThematically(color, VA_ERROR_MESSAGE_TEXT_COLOR--EXAGGERATED);
    @include applyThematically(color, VA_HEADER_SITE_GUIDANCE_TEXT_COLOR--WARNING);
    justify-self: center;
    margin: auto;
    max-width: 100rem;
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-shadow: 0 1px 1px rgba(#000000, 0.5);
  }
 .header {
   min-height: 0 !important;
 }
  @import "~@department-of-veterans-affairs/formation/sass/site/m-vet-nav";
  #vetnav-menu > li:first-of-type {
    display: block;
  }
  #vetnav-menu > li {
    margin-bottom: 0;
  }
  .va-header-logo {
    width: 100%;
    max-width: 264px;
    height: auto;
  }
  #menu-rule {
    // border-bottom: 1px solid #fff;
    // margin-left: -0.55em;
    // padding-bottom: 1em;
    // padding-right: 1.2em;
    // border-bottom: 1px solid #fff;
    // margin-left: -.55em;
    // padding-bottom: 1em;
    // padding-right: 1.2em;
    @media only screen and (min-width: 767px) {
      border-bottom: 1px solid #fff;
      margin-left: -0.55em;
      padding-bottom: 1em;
      padding-right: 1.2em;
    }
    // @media only screen and (min-width: 62.5em) {
    //   border-bottom: 1px solid #fff;
    //   margin-left: -0.55em;
    //   padding-bottom: 1em;
    //   padding-right: 1.2em;
    // }
  }
  .navigation-item {
    -webkit-font-smoothing: antialiased;
    // background-color: #003E73;
    background-position: right 3rem center;
    background-repeat: no-repeat;
    background-size: 1.3rem auto;
    border-radius: 0;
    border-top: 3px solid transparent;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    padding: 1.6rem;
    text-align: left;
    text-decoration: none;
    width: 100%;
    box-shadow: inset 0 -5px 0 #0071bb;
    &:hover {
      background-color: #003E73;
    }
  }
}
