@include block("page-footer") {
  grid-area: VA-Footer;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "Help-Text" "Version-Indicator" "Footer";
  gap: 1em 1em;
  margin-top: 1em;
  padding-bottom: 0;
  &__help-text {
    grid-area: Help-Text;
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 100rem;
    width: calc(100% - 2rem);
    justify-self: center;
    @media all and (-ms-high-contrast:none) {
      margin:auto
    }
  }
  &__help-heading {
    border-bottom: 3px solid transparent;
    @include applyThematically(
      border-bottom-color,
      VA_PROCESS_STEP_ACTIVE_COLOR
    );
    margin-bottom: 0.3rem;
    padding-bottom: 0.3rem;
    width: 100%;
  }
  &__subtitle {
    grid-area: Version-Indicator;
    @extend .vads-u-font-size--sm;
    max-width: 100rem;
    border-top: 1px solid transparent;
    @include applyThematically(border-top-color, VA_PAGE_TEXT_COLOR);
    padding-top: 1em;
    align-self: center;
    justify-self: center;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    @media all and (-ms-high-contrast:none) {
      margin:auto
    }
  }
  .footer {
    grid-area: Footer;
    @media all and (-ms-high-contrast:none) {
      -ms-grid-row: 4;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      @media only screen and (min-width: 62.5em) {
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
      }
    }
    width: 100%;
    color: #ffffff;
    margin: 0 auto;
    background: #112e51;
    position: relative;
    overflow: hidden;
  }
  .va-footer-logo {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .va-footer-links-bottom {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .va-footer-links-bottom ul {
    padding-left: 0;
    padding-bottom: 3em;
    list-style: none;

    li {
      list-style: none;
      display: inline-block;
      color: #ffffff;

      a,
      a:visited {
        color: #fff;
        font-weight: 400;
      }

      &:after {
        content: "|";
        padding: 0 1rem;
      }
    }
  }

  .footer-banner {
    padding-top: 1.5em;
    padding-bottom: 2em;
  }
  .va-footer-logo > img {
    width: 200px;
    height: auto;
  }
}
