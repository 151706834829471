@include block("ui-graph") {
  padding-bottom: 1rem;
  @include element("title") {
    @extend .text-style__headline;
    text-align: left;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0rem;
  }
  @include element("header") {
    @extend .positioning__default-pad;
    padding-bottom: 0;
  }
  @include element("date-controls-container") {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
  }
  @include element("meditation-checkbox-container") {
    padding-left: 1rem;
  }
  @include element("meditation-checkbox-container__label") {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    @include applyThematically(color, LABEL_COLOR);
  }
  @include element("meditation-checkbox-container__checkbox") {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
  }
  @include element("custom-tooltip") {
    border: solid 0.8px transparent;
    @include applyThematically(border-color, MODAL_BORDER_COLOR);
    @extend .--TOOLTIP-MATERIAL;
    @include boxShadow($level: 2);
    border-radius: 0.5rem;
    padding: 0.625rem;
    @include modifier("hidden") {
      visibility: visible;
      @extend .animated-ui--fast;
      @extend .zoomOutmacOS;
    }
    @include modifier("visible") {
      @extend .animated-ui--fast;
      @extend .zoomInmacOS;
    }
  }
  @include element("custom-tooltip-label") {
    @include applyThematically(color, LABEL_COLOR);
    font-weight: 700;
  }
  @include element("custom-tooltip-text") {
    @include applyThematically(color, LABEL_COLOR);
  }
  @include element("value-tooltip") {
    background-color: #ee9814;
    @include applyThematically(color, LABEL_COLOR);
    font-weight: 700;
    width: 2rem;
    height: 1.4rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: translateX(50vw);
  }
  @include element("value-tooltip-text") {
    line-height: 1rem;
    margin-bottom: -1px;
  }
}

#mood-events {
  // hotfix for removing top cartesian grid axes
  .recharts-cartesian-grid-horizontal {
    line {
      @include applyThematically(stroke, INPUT_BORDER_COLOR);
    }
    & line:last-child {
      stroke: none;
      stroke-opacity: 0;
    }
  }
}
