@include block("landing-page") {
  overflow-x: hidden;
  // display: flex;
  //   flex-direction: column;
  // padding-top: calc(#{convertBase16ToBase10(3rem)} + env(safe-area-inset-top, 0));

  display: grid;
  grid-template-columns: 1em 1fr 1em;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "VA-Header VA-Header VA-Header" "Breadcrumbs Breadcrumbs Breadcrumbs" ". Content ." "VA-Footer VA-Footer VA-Footer";
  position: relative;
  @media all and (-ms-high-contrast:none) {
    display: -ms-grid;
    -ms-grid-columns: 1em 1fr 1em;
    -ms-grid-rows: auto auto auto auto;
  }

  &__content {
    // // @extend .positioning__large-pad;
    // flex: 4;
    // padding-bottom: 1.5rem;
    grid-area: Content;
    max-width: 62.5em;
    align-self: center;
    justify-self: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "Page-Introduction" "Track-Existing-Claim" "Submit-New-Claim" "Footer";
    @media all and (-ms-high-contrast:none) {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      margin-left:auto;
      margin-right: auto;
      display: -ms-grid;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: auto auto auto auto;
    }
    // @media all and (-ms-high-contrast:none) {
    //   display: -ms-grid;
    //   -ms-grid-columns: 1fr;
    //   -ms-grid-rows: auto auto auto auto;
    // }

    @media only screen and (min-width: 62.5em) {
      display: grid;
      grid-template-columns: 1fr 46rem;
      grid-template-rows: auto auto auto;
      grid-template-areas: "Page-Introduction Page-Introduction" "Submit-New-Claim Track-Existing-Claim" "Footer Footer";
      gap: 0 1em;
    }
    @media only all and (-ms-high-contrast:none) and (min-width: 62.5em) {
      display: -ms-grid;
        -ms-grid-columns: 1fr 46rem;
        -ms-grid-rows: auto auto auto;
    }
  }
}
