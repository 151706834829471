.ui-form-label {
  @extend .--disable-select;
  @include applyThematically(color, "INPUT_LABEL_TEXT_COLOR");
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  // &:disabled {
  //     @include applyThematically(color, 'INPUT_LABEL_TEXT_COLOR--DISABLED');
  // }
  // &:hover {
  //     @include applyThematically(color, 'INPUT_LABEL_TEXT_COLOR--HOVER');
  //     &+ input {
  //         @include applyThematically(border-color, 'INPUT_LABEL_TEXT_COLOR--HOVER');
  //     }
  // }
}

.--APPLICATION-MATERIAL {
  @include lightMode() {
    background-color: rgba(
      getThematicValue(VA_PAGE_BACKGROUND_COLOR, light),
      0.98
    );
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(VA_PAGE_BACKGROUND_COLOR, light),
        0.7
      );
      backdrop-filter: blur(1.6rem) saturate(1.5);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.6rem) saturate(1.5);
      background-color: rgba(
        getThematicValue(VA_PAGE_BACKGROUND_COLOR, light),
        0.7
      );
    }
  }
  @include darkMode() {
    background-color: rgba(
      getThematicValue(VA_PAGE_BACKGROUND_COLOR, dark),
      0.98
    );
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(VA_PAGE_BACKGROUND_COLOR, dark),
        0.6
      );
      backdrop-filter: blur(1.6rem) saturate(1.5);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.6rem) saturate(1.5);
      background-color: rgba(
        getThematicValue(VA_PAGE_BACKGROUND_COLOR, dark),
        0.6
      );
    }
  }
}

.--APPLICATION-MATERIAL--SECONDARY {
  @include lightMode() {
    // background-color: rgba(getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, light), 0.98);
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, light),
        0.2
      );
      backdrop-filter: blur(0.05rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(0.25rem);
      background-color: rgba(
        getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, light),
        0.8
      );
    }
  }
  @include darkMode() {
    background-color: rgba(
      getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, dark),
      0.98
    );
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, dark),
        0.8
      );
      backdrop-filter: blur(1.25rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.25rem);
      background-color: rgba(
        getThematicValue(SECONDARY_APPLICATION_BACKGROUND_COLOR, dark),
        0.8
      );
    }
  }
}

.--HEADER-MATERIAL {
  @include lightMode() {
    background: getThematicValue(HEADER_BACKGROUND_GRADIENT, light);
    @supports (backdrop-filter: blur(0)) {
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, light);
      backdrop-filter: blur(1.25rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.25rem);
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, light);
    }
  }
  @include darkMode() {
    background: getThematicValue(HEADER_BACKGROUND_GRADIENT, dark);
    @supports (backdrop-filter: blur(0)) {
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, dark);
      backdrop-filter: blur(1.25rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.25rem);
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, dark);
    }
  }
}

.--HEADER-MATERIAL--SECONDARY {
  @media only screen and (max-width: $metric__break-sm) {
    @include lightMode() {
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT, light);
      @supports (backdrop-filter: blur(0)) {
        background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, light);
        backdrop-filter: blur(1.25rem);
      }
      @supports (-webkit-backdrop-filter: blur(0)) {
        -webkit-backdrop-filter: blur(1.25rem);
        background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, light);
      }
    }
    @include darkMode() {
      background: getThematicValue(HEADER_BACKGROUND_GRADIENT, dark);
      @supports (backdrop-filter: blur(0)) {
        background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, dark);
        backdrop-filter: blur(1.25rem);
      }
      @supports (-webkit-backdrop-filter: blur(0)) {
        -webkit-backdrop-filter: blur(1.25rem);
        background: getThematicValue(HEADER_BACKGROUND_GRADIENT--OPAQUE, dark);
      }
    }
  }
}

.--TOOLTIP-MATERIAL {
  @include lightMode() {
    background-color: rgba(
      getThematicValue(CARD_BACKGROUND_COLOR, light),
      0.98
    );
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(CARD_BACKGROUND_COLOR, light),
        0.8
      );
      backdrop-filter: blur(1.25rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.25rem);
      background-color: rgba(
        getThematicValue(CARD_BACKGROUND_COLOR, light),
        0.8
      );
    }
  }
  @include darkMode() {
    background-color: rgba(getThematicValue(CARD_BACKGROUND_COLOR, dark), 0.98);
    @supports (backdrop-filter: blur(0)) {
      background-color: rgba(
        getThematicValue(CARD_BACKGROUND_COLOR, dark),
        0.8
      );
      backdrop-filter: blur(1.25rem);
    }
    @supports (-webkit-backdrop-filter: blur(0)) {
      -webkit-backdrop-filter: blur(1.25rem);
      background-color: rgba(
        getThematicValue(CARD_BACKGROUND_COLOR, dark),
        0.8
      );
    }
  }
}

.--HAIRLINE-BORDER-BOTTOM {
  border-bottom: 0.5px solid transparent;
  @include lightMode() {
    border-color: getThematicValue(APPLICATION_BORDER_COLOR, light);
  }
  @include darkMode() {
    border-color: getThematicValue(APPLICATION_BORDER_COLOR, dark);
  }
}

.--OBJECT-FOCUS--GLOW {
  border-bottom: 0.5px solid transparent;
  @include lightMode() {
    border-color: getThematicValue(APPLICATION_BORDER_COLOR, light);
  }
  @include darkMode() {
    border-color: getThematicValue(APPLICATION_BORDER_COLOR, dark);
  }
}

@mixin boxShadow($level: 1, $color: #000000, $animated: false) {
  $level: 1 !default;
  $color: #000000 !default;
  // $color: $color-black !default;
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba($color, 0.12), 0 1px 2px rgba($color, 0.24);
    @if $animated {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        box-shadow: 0 14px 28px rgba($color, 0.25),
          0 10px 10px rgba($color, 0.22);
      }
    }
  } @else if $level == 1.5 {
    box-shadow: 0 2px 6px rgba($color, 0.12), 0 1px 4px rgba($color, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 1.25rem rgba($color, 0.16),
      0 3px 1.25rem rgba($color, 0.23);
    @if $animated {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        box-shadow: 0 14px 28px rgba($color, 0.25),
          0 10px 10px rgba($color, 0.22);
      }
    }
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba($color, 0.19),
      0 1.25rem 1.25rem rgba($color, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba($color, 0.25), 0 10px 10px rgba($color, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba($color, 0.3), 0 15px 12px rgba($color, 0.22);
  } @else {
    box-shadow: 0 3px 1.25rem rgba($color, 0.16),
      0 3px 1.25rem rgba($color, 0.23);
    @if $animated {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        box-shadow: 0 14px 28px rgba($color, 0.25),
          0 10px 10px rgba($color, 0.22);
      }
    }
  }
}

@mixin focusGlow($border-color) {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  box-shadow: 0 0 0.3rem $border-color;
}
