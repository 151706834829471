@include block("ui-tooltip") {
  border: solid 0.8px transparent;
  @include applyThematically(border-color, MODAL_BORDER_COLOR);
  @extend .--TOOLTIP-MATERIAL;
  @include boxShadow($level: 2);
  border-radius: 0.5rem;
  padding: 0.625rem;
  @include modifier("hidden") {
    visibility: visible;
    @extend .animated-ui;
    @extend .zoomOutmacOS;
  }
  @include modifier("visible") {
    @extend .animated-ui;
    @extend .zoomInmacOS;
  }
  @include modifier("compact") {
    padding: 0 0 0 0.5rem;
  }
  @include element("time") {
    margin-bottom: 0.3rem;
  }
  @include element("local-datetime") {
    @extend .text-style__caption1;
    @include applyThematically(color, SECONDARY_LABEL_COLOR);
    border-bottom: 0.8px solid transparent;
    padding-bottom: 0.35rem;
    margin-bottom: 0.35rem;
    margin-top: 0;
    display: block;
    @include applyThematically(border-color, APPLICATION_BORDER_COLOR);
  }
  @include element("event-offset") {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  @include element("timestamp") {
    @extend .text-style__caption1;
    @include applyThematically(color, LABEL_COLOR);
    @include modifier("alternate-zone") {
      @extend .text-style__caption2;
      margin-top: 0;
      font-weight: 400;
      @include applyThematically(color, SECONDARY_LABEL_COLOR);
    }
  }
  @include element("datestamp") {
    @extend .text-style__caption1;
    @include applyThematically(color, LABEL_COLOR);
    @include modifier("alternate-zone") {
      @extend .text-style__caption2;
      margin-top: 0;
      font-weight: 400;
      @include applyThematically(color, SECONDARY_LABEL_COLOR);
    }
  }
  @include element("custom-tooltip-label") {
    @include applyThematically(color, LABEL_COLOR);
    font-weight: 700;
  }
  @include element("custom-tooltip-text") {
    @include applyThematically(color, LABEL_COLOR);
  }
}
