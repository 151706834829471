@include block("ui-form") {
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  width: 100%;
  max-width: 31rem;
  @media only screen and (min-width: 22rem) {
    padding: 1rem;
  }
  // TODO: Cards and exapnded view forms should be broken apart.
  // TODO: Extract others into more sections such as form field, dialog, button group etc.
  @include modifier("card") {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2rem);
    max-width: 31rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    @include applyThematically(background-color, CARD_BACKGROUND_COLOR);
    @include lightMode() {
      border: 0.8px solid getThematicValue(MODAL_BORDER_COLOR, light);
    }
    @include darkMode() {
      border: 0.8px solid getThematicValue(MODAL_BORDER_COLOR, dark);
    }
    @include bindElementModifier("title", "inline");
    @include bindElementModifier("input", "full-width");
  }
  @include modifier("collapse-bottom") {
    padding-bottom: 0;
  }
  @include element("container") {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-bottom: 0;
    padding: 1rem;
    justify-content: flex-start;
  }
  @include element("heading") {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @include element("title") {
    @extend .text-style__headline;
    margin-top: 0;
    line-height: 1.4em;
    flex: 1;
    margin-bottom: 0.25rem;
    @include modifier("inline") {
      display: inline-block;
      align-self: flex-start;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
  @include element("heading-actions") {
    margin-right: -0.65rem;
  }
  @include element("description") {
    @extend .text-style__copy;
    @include applyThematically(color, "PRIMARY_BODY_TEXT_COLOR");
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.6rem;
    @media only screen and (min-width: 22rem) {
      padding-bottom: 1rem;
    }
    @include modifier("error") {
      color: $PRIMARY_LABEL_TEXT_COLOR--WARNING;
    }
  }
  @include element("field") {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    @media only screen and (min-width: 22rem) {
      margin-bottom: 1rem;
    }
    max-width: 31rem;
    width: 100%;
    &:focus-within {
      // @include bindElementModifier("field-label", "focused");
      @include bindElementModifier("input", "focused");
      @include bindElementModifier("text-area", "focused");
    }
    &:hover {
      // @include bindElementModifier("field-label", "hover");
      @include bindElementModifier("input", "hover");
      @include bindElementModifier("text-area", "hover");
    }

    > [class^="css-"] {
      * {
        @include lightMode() {
          color: getThematicValue(LABEL_COLOR, light);
          border-color: getThematicValue(INPUT_BORDER_COLOR, light);
        }
        @include darkMode() {
          color: getThematicValue(LABEL_COLOR, dark);
          border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
        }
      }
      > [class^="css-"] {
        @extend .--APPLICATION-MATERIAL;
        border: solid 1px transparent;
        background-color: transparent;
        // border is intentionally above these @includes
        @include lightMode() {
          border-color: getThematicValue(INPUT_BORDER_COLOR, light);
        }
        @include darkMode() {
          border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
        }
      }
      > [class^="css-"]:nth-of-type(2) {
        @extend .--APPLICATION-MATERIAL;
        border: solid 1px transparent;
        background-color: transparent;
        // border is intentionally above these @includes
        @include lightMode() {
          border-color: getThematicValue(INPUT_BORDER_COLOR, light);
        }
        @include darkMode() {
          border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
        }
        > [class^="css-"] {
          > [class^="css-"]:hover {
            @include applyThematically(background-color, SYSTEM_BLUE);
          }
        }
      }
    }
  }
  @include element("field-label") {
    @extend .ui-form-label;
    // @include modifier("hover") {
    //   @include applyThematically(color, INPUT_BORDER_COLOR--HOVER);
    // }
    // @include modifier("focused") {
    //   @include applyThematically(color, INPUT_BORDER_COLOR--FOCUS);
    // }
  }
  @include element("input") {
    &[type="text"],
    &[type="password"] {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
    }
    // ^ standardize field appearance (note you may need to add more types here)
    &::-webkit-contacts-auto-fill-button {
      @include applyThematically(color, LABEL_COLOR);
    }
    // ^ attempt to fix safari autofill icon color in dark mode
    border: solid 1px transparent;
    // ^ border is intentionally above these @includes
    @include lightMode() {
      color: getThematicValue(LABEL_COLOR, light);
      border-color: getThematicValue(INPUT_BORDER_COLOR, light);
      background-color: getThematicValue(INPUT_BACKGROUND_COLOR--FOCUS, light);
    }
    @include darkMode() {
      color: getThematicValue(LABEL_COLOR, dark);
      border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
      background-color: getThematicValue(INPUT_BACKGROUND_COLOR--FOCUS, dark);
    }
    display: block;
    border-radius: 4px;
    padding: 4px;
    width: 100%;
    resize: vertical;
    font-size: 1rem;
    border-radius: 0.25rem;
    padding: 0.563rem 0.75rem;
    &:-webkit-autofill {
      @include lightMode() {
        -webkit-box-shadow: 0 0 0 10rem #d8d4c0 inset;
        // background-color: #D8D4C0;
        -webkit-box-shadow: 0 0 0 10rem #fef1ce inset;
        background-color: #fef1ce;
        border-color: getThematicValue(SYSTEM_YELLOW, light);
      }
      @include darkMode() {
        -webkit-box-shadow: 0 0 0 10rem #625c41 inset;
        background-color: #625c41;
        -webkit-box-shadow: 0 0 0 10rem #4e461e inset;
        background-color: #4e461e;
        border-color: getThematicValue(SYSTEM_YELLOW, dark);
      }
      @include applyThematically(-webkit-text-fill-color, LABEL_COLOR);
    }
    &:disabled {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--DISABLED
      );
      @include applyThematically(color, INPUT_TEXT_COLOR--DISABLED);
      &::placeholder {
        @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--DISABLED);
      }
    }
    @include modifier("hover") {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--HOVER);
      &::placeholder {
        @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--HOVER);
      }
      &:disabled {
        @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
        &::placeholder {
          @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--DISABLED);
        }
      }
      &:-webkit-autofill {
        @include lightMode() {
          @include focusGlow(rgba(getThematicValue(SYSTEM_YELLOW, light), 0.5));
          -webkit-box-shadow: 0 0 0 10rem #fef1ce inset;
          background-color: #fef1ce;
          border-color: getThematicValue(SYSTEM_YELLOW, light);
        }
        @include darkMode() {
          @include focusGlow(rgba(getThematicValue(SYSTEM_YELLOW, dark), 0.5));
          -webkit-box-shadow: 0 0 0 10rem #4e461e inset;
          background-color: #4e461e;
          border-color: getThematicValue(SYSTEM_YELLOW, dark);
        }
        @include applyThematically(-webkit-text-fill-color, LABEL_COLOR);
      }
    }
    @include modifier("focused") {
      &:-webkit-autofill {
        @include lightMode() {
          @include focusGlow(rgba(getThematicValue(SYSTEM_YELLOW, light), 0.9));
          -webkit-box-shadow: 0 0 0 10rem #fef1ce inset,
            0 0 0.3rem rgba(255, 204, 0, 0.5);
          background-color: #fef1ce;
          border-color: getThematicValue(SYSTEM_YELLOW, light);
        }
        @include darkMode() {
          @include focusGlow(rgba(getThematicValue(SYSTEM_YELLOW, dark), 0.9));
          -webkit-box-shadow: 0 0 0 10rem #4e461e inset,
            0 0 0.3rem rgba(255, 214, 10, 0.5);
          background-color: #4e461e;
          border-color: getThematicValue(SYSTEM_YELLOW, dark);
        }
        @include applyThematically(-webkit-text-fill-color, LABEL_COLOR);
      }
      @include lightMode() {
        @include focusGlow(
          rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, light), 0.5)
        );
      }
      @include darkMode() {
        @include focusGlow(
          rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, dark), 0.5)
        );
      }
      @include applyThematically(border-color, INPUT_BORDER_COLOR--FOCUS);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--FOCUS
      );
    }
    @include modifier("full-width") {
      width: 100%;
    }
  }
  @include element("radio-group") {
    // border: solid 1px transparent;
    // border is intentionally above these @includes
    @include lightMode() {
      // color: getThematicValue(LABEL_COLOR, light);
      border-color: getThematicValue(INPUT_BORDER_COLOR, light);
    }
    @include darkMode() {
      // color: getThematicValue(LABEL_COLOR, dark);
      border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
    }
    // background-color: transparent;
    display: block;
    // border-radius: 4px;
    // padding: 4px;
    width: 100%;
    resize: vertical;
    font-size: 1rem;
    border-radius: 0.25rem;
    // padding: 0.563rem 0.75rem;
    &:disabled {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--DISABLED
      );
      @include applyThematically(color, INPUT_TEXT_COLOR--DISABLED);
      &::placeholder {
        @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--DISABLED);
      }
    }
    @include modifier("hover") {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--HOVER);
      &::placeholder {
        @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--HOVER);
      }
      &:disabled {
        @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
        &::placeholder {
          @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--DISABLED);
        }
      }
    }
    @include modifier("focused") {
      @include lightMode() {
        @include focusGlow(
          rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, light), 0.5)
        );
      }
      @include darkMode() {
        @include focusGlow(
          rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, dark), 0.5)
        );
      }
      @include applyThematically(border-color, INPUT_BORDER_COLOR--FOCUS);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--FOCUS
      );
    }
    @include modifier("full-width") {
      width: 100%;
    }
  }
  @include element("radio-option") {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @include element("radio-button") {
    position: absolute;
    // top: 0;
    // bottom: 0;
    left: 1rem;
    display: inline;
    margin-right: 0.5rem;
    margin-top: -0.05rem;
    &:disabled + .ui-form__radio-label {
      @extend .ui-form__radio-label--disabled;
      @extend .ui-form__radio-label--hover-disabled;
    }
    &:checked + .ui-form__radio-label {
      @extend .ui-form__radio-label--active;
    }
    &:hover + .ui-form__radio-label {
      @extend .ui-form__radio-label--hover;
    }
  }
  @include element("radio-label") {
    @extend .--disable-select;
    border: solid 1px transparent;
    // border is intentionally above these @includes
    @include lightMode() {
      color: getThematicValue(LABEL_COLOR, light);
      // border-color: getThematicValue(INPUT_BORDER_COLOR, light);
    }
    @include darkMode() {
      color: getThematicValue(LABEL_COLOR, dark);
      // border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
    }
    @include applyThematically(background-color, INPUT_BACKGROUND_COLOR--FOCUS);

    width: 100%;
    min-height: 2.75rem;
    box-sizing: border-box;
    display: inline;
    flex: 1;
    border-radius: 4px;
    // background-color: transparent;
    padding: 0.75rem 0.75rem 0.75rem 2.75rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font: -apple-system-body;
    font-weight: 600;
    // font-size: 1.1rem;
    @include modifier("disabled") {
      // @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
      // @include applyThematically(background-color, INPUT_BACKGROUND_COLOR--DISABLED);
      background-color: transparent;
      border-color: transparent;
      @include applyThematically(color, SYSTEM_GRAY_2);
      &:hover {
        // @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
        // @include applyThematically(background-color, INPUT_BACKGROUND_COLOR--DISABLED);
        background-color: transparent;
        border-color: transparent;
        @include applyThematically(color, SYSTEM_GRAY_2);
      }
    }
    @include modifier("hover") {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--HOVER);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--FOCUS
      );
    }
    @include modifier("hover-disabled") {
      background-color: transparent;
      border-color: transparent;
      @include applyThematically(color, SYSTEM_GRAY_2);
    }
    @include modifier("active") {
      color: #ffffff;
      @include lightMode() {
        // @include focusGlow(rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, light), 0.5));
        text-shadow: 0 1px 0 darken(getThematicValue(SYSTEM_BLUE, light), 10);
      }
      @include darkMode() {
        // @include focusGlow(rgba(getThematicValue(INPUT_BORDER_COLOR--FOCUS, dark), 0.5));
        text-shadow: 0 1px 0 darken(getThematicValue(SYSTEM_BLUE, dark), 10);
      }
      @include applyThematically(border-color, INPUT_BORDER_COLOR--FOCUS);
      @include applyThematically(background-color, INPUT_BORDER_COLOR--FOCUS);
    }
  }
  @include element("text-area") {
    border: solid 1px transparent;
    // border is intentionally above these @includes
    @include lightMode() {
      color: getThematicValue(LABEL_COLOR, light);
      border-color: getThematicValue(INPUT_BORDER_COLOR, light);
    }
    @include darkMode() {
      color: getThematicValue(LABEL_COLOR, dark);
      border-color: getThematicValue(INPUT_BORDER_COLOR, dark);
    }
    background-color: transparent;
    display: block;
    border-radius: 4px;
    padding: 4px;
    width: 50%;
    resize: vertical;
    width: 100%;
    min-height: 1.8125em;
    @include modifier("hover") {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--HOVER);
      &::placeholder {
        @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--HOVER);
      }
      &:disabled {
        @include applyThematically(border-color, INPUT_BORDER_COLOR--DISABLED);
        &::placeholder {
          @include applyThematically(color, INPUT_PLACEHOLDER_COLOR--DISABLED);
        }
      }
    }
    @include modifier("focused") {
      @include applyThematically(border-color, INPUT_BORDER_COLOR--FOCUS);
      @include applyThematically(
        background-color,
        INPUT_BACKGROUND_COLOR--FOCUS
      );
    }
    @include modifier("one-line") {
      height: 1.8125em;
    }
    // 19
    @include modifier("two-line") {
      height: 1.1875em * 2 + 1.8125em;
    }
    // 19
    @include modifier("three-line") {
      height: 1.1875em * 3 + 1.8125em;
    }
    // 19
    @include modifier("four-line") {
      height: 1.1875em * 4 + 1.8125em;
    }
    // 19
    @include modifier("five-line") {
      height: 1.1875em * 5 + 1.8125em;
    }
    // 19
    @include modifier("six-line") {
      height: 1.1875em * 6 + 1.8125em;
    }
    // 19
    @include modifier("seven-line") {
      height: 1.1875em * 7 + 1.8125em;
    }
    // 18
    @include modifier("eight-line") {
      height: 1.1875em * 8 + 1.8125em;
    }
    // 20
    @include modifier("nine-line") {
      height: 1.1875em * 9 + 1.8125em;
    }
    // 19
    @include modifier("ten-line") {
      height: 1.1875em * 10 + 1.8125em;
    }
  }
  @include element("footer") {
    width: 100%;
    max-width: 31rem;
    margin-top: 0.425rem;
    margin-bottom: 0.05rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  @include element("message") {
    @extend .positioning__default-pad;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    display: inline;
  }
}
