@include block("tos") {
  @extend .layout-main-container;
  @extend .layout-main-container--application-header-offset;
  // width: 100%;
  // height: calc(100vh - #{$metric__nav-height});
  // margin-top: $metric__nav-height;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(3.875rem + env(safe-area-inset-bottom));

  &--read-only {
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  }

  &__iframe {
    width: 100%;
    height: calc(100vh - #{$metric__nav-height});
  }
  &__title {
    @extend .text-style__headline;
    // padding-top: 1rem;
    // margin-top: 0;
    text-align: center;
  }
  &__body-top {
    @extend .text-style__copy;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-top: 2rem;
  }
  &__body-section {
    @extend .text-style__copy;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &--emphasis {
      // padding: 0.5rem;
      font-weight: 700;
    }
  }
  &__underline {
    text-decoration: underline;
  }
  &__bold-underline {
    font-weight: bold;
    text-decoration: underline;
  }
  // &__agree-button {
  //   margin-left: 2rem;
  // }
  &__footer {
    // footer is always present, becasuse we want to make sure we account for the bottom safe area here
    @extend .positioning__default-pad;
    @extend .--APPLICATION-MATERIAL;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
