// Layout Prototype: Main Container
// # About
// This Layout Prototype provides the
@include block("layout-main-container") {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: $metric__max-width;
  position: relative;
  overscroll-behavior: contain;

  &--application-header-offset {
    @extend .row;
    position: relative;
    flex: 1;
    // padding-top: calc(#{$metric__nav-height} / 2);
    margin-top: calc(env(safe-area-inset-top) + #{$metric__nav-height} / 2);

    @media only screen and (min-width: $metric__break-sm) {
      min-height: 0;
      margin-top: calc(env(safe-area-inset-top) + #{$metric__nav-height});
      // padding-top: #{$metric__nav-height};
    }
  }
}
