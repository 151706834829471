.animated-ui {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &--fast {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
  }
}

@-webkit-keyframes zoomOutmacOS {
  from {
    opacity: 1;
  }
  99% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOutmacOS {
  from {
    opacity: 1;
  }
  99% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 0;
  }
}
.zoomOutmacOS {
  -webkit-animation-name: zoomOutmacOS;
  animation-name: zoomOutmacOS;
}

@-webkit-keyframes zoomInmacOS {
  from {
    opacity: 0;
  }
  1% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomInmacOS {
  from {
    opacity: 0;
  }
  1% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 1;
  }
}
.zoomInmacOS {
  -webkit-animation-name: zoomInmacOS;
  animation-name: zoomInmacOS;
}
