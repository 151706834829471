@include block("veterans-affairs-breadcrumbs") {
  grid-area: Breadcrumbs;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  @extend .va-nav-breadcrumbs;
  position: sticky;
  top: 0;
  &__list {
    @extend .row;
    @extend .va-nav-breadcrumbs-list;
    @extend .columns;
  }
}
