@include block("claim-status-card") {
  @extend .usa-alert;
  max-width: 46rem;

  @include modifier("loading") {
    &::before {
      @extend .fa;
      @extend .fa-spinner;
      @extend .fa-pulse;
      @extend .fa-fw;
      content: "\f110";
    }
    @include applyThematically(border-color, VA_PROCESS_STEP_COLOR);
    h3 {
      @include applyThematically(color, INPUT_LABEL_TEXT_COLOR--DISABLED);
    }
  }
  @include modifier("preparingToUpload") {
    &::before {
      @extend .fa;
      @extend .fa-spinner;
      @extend .fa-pulse;
      @extend .fa-fw;
      content: "\f110";
    }
    @include applyThematically(border-color, VA_PROCESS_STEP_ACTIVE_COLOR);
  }
  @include modifier("pending") {
    @extend .usa-alert-info;
  }
  @include modifier("uploading") {
    &::before {
      @extend .fa;
      @extend .fa-spinner;
      @extend .fa-pulse;
      @extend .fa-fw;
      content: "\f110";
    }
    @extend .usa-alert-info;
    @include applyThematically(border-color, VA_PROCESS_STEP_ACTIVE_COLOR);
  }
  @include modifier("uploaded") {
    @extend .usa-alert-success;
    @include applyThematically(border-color, VA_PROCESS_STEP_COMPLETE_COLOR);
  }
  @include modifier("received") {
    @extend .usa-alert-success;
    @include applyThematically(border-color, VA_PROCESS_STEP_COMPLETE_COLOR);
  }
  @include modifier("processing") {
    @extend .usa-alert-success;
  }
  @include modifier("success") {
    @extend .usa-alert-success;
  }
  @include modifier("vbms") {
    @extend .usa-alert-success;
  }
  @include modifier("error") {
    @extend .usa-alert-error;
  }
}
