@include block("submit-new-document") {
  grid-area: Submit-New-Claim;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  @media only all and (-ms-high-contrast:none) and (min-width: 62.5em) {
    -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
  }
  &__title {
    @media only screen and (min-width: 62.5em) {
      margin-top: 2rem;
    }
  }
  &__ticket {
    max-width: 46rem;
    @include applyThematically(background-color, VA_ALERT_BACKGROUND_COLOR);
    padding: 0.25em 0.75em;
    clear: both;
    margin: 0.5em 0;
  }
  &__ticket-label {
    @extend h3;
  }
  &__ticket-value {
    // @extend h6;

    span {
      // @extend h4;
    }
  }
  &__ticket-print-reminder {
    // max-width: 46rem;
    font-style: italic;
  }
    @media (prefers-color-scheme: dark) {
      a, a:visited {
        color: #6bb2ff !important;
    }
  }
  ul {
    max-width: 46rem;
  }
}
