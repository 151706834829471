@include block("upload-page") {
  grid-area: Submit-New-Claim;
  @media all and (-ms-high-contrast:none) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  @media only all and (-ms-high-contrast:none) and (min-width: 62.5em) {
    -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
  }
  @include element("error-message") {
    @include applyThematically(color, VA_ERROR_MESSAGE_TEXT_COLOR);
    font-weight: 700;
  }
}
