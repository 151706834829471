// TODO: This component *improperly* uses bem and should be fixed
$button-themes: (
  "default": (
    "color": SYSTEM_BLUE,
    // We may eventually change these adjustment values to be based on achieving a certain luminosity value
      "lightAdjustmentRatio": 7,
    "darkAdjustmentRatio": 7,
  ),
  "light": (
    "color": SYSTEM_GRAY_6,
    "lightAdjustmentRatio": 6,
    "darkAdjustmentRatio": 3,
  ),
  "success": (
    "color": SYSTEM_GREEN,
    "lightAdjustmentRatio": 5,
    "darkAdjustmentRatio": 6,
  ),
  "confirm": (
    "color": SYSTEM_TEAL,
    "lightAdjustmentRatio": 7,
    "darkAdjustmentRatio": 9,
  ),
  "alert": (
    "color": SYSTEM_ORANGE,
    "lightAdjustmentRatio": 5,
    "darkAdjustmentRatio": 8,
  ),
  "warning": (
    "color": SYSTEM_RED,
    "lightAdjustmentRatio": 15,
    "darkAdjustmentRatio": 18,
  ),
  "info": (
    "color": SYSTEM_INDIGO,
    "lightAdjustmentRatio": 10,
    "darkAdjustmentRatio": 10,
  ),
  "system": (
    "color": SYSTEM_YELLOW,
    "lightAdjustmentRatio": 3,
    "darkAdjustmentRatio": 10,
  ),
);

@include block("ui-button") {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @extend .--disable-select;

  padding: 0.6rem 1.6rem;
  font-size: 1rem;
  font-weight: 700;

  border: none;
  border-radius: calc(1rem / 4);
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;

  margin: 0;

  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  + .ui-button {
    // We do this to ensure buttons side-by-side do not touch
    // a little funky math here, but taking one half of the border radius, and making it the default
    // gives us a little extra optical positioning. This is done because rounded objects should *slightly*
    // collapse towards each other.
    margin-left: calc(1em - (1em / 8));
  }

  &:hover {
    text-decoration: none;

    @include bindElementModifier("hidden-panel", "neutralState");
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  @include modifier("loading") {
    color: transparent !important;
    &:before {
      color: "#FFFFFF";
      content: "Loading...";
      top: 0;
      left: 0;
      text-align: center;
      position: absolute;
      width: 100%;
    }
  }

  @include element("style") {
    @include modifier("proportional") {
      flex: 1;
    }
    @include modifier("full-width") {
      width: 100%;
    }
  }

  @include element("color") {
    // This gives us all of our colors.
    @for $i from 1 through length($button-themes) {
      $themes: map-keys($button-themes);
      $this-theme: to-string(nth($themes, $i));
      $theme-color: map-deep-get($button-themes, $this-theme, "color");

      &--#{$this-theme} {
        @include lightMode() {
          background-color: getThematicValue($theme-color, light);
          color: chooseContrastingColor(getThematicValue($theme-color, light));
          &:hover {
            background-color: darken(
              getThematicValue($theme-color, light),
              map-deep-get($button-themes, $this-theme, "lightAdjustmentRatio")
            );
          }
          &:active {
            background-color: darken(
              getThematicValue($theme-color, light),
              map-deep-get($button-themes, $this-theme, "lightAdjustmentRatio") *
                2
            );
          }
          &.ui-button__type--icon {
            background-color: transparent;
            color: getThematicValue($theme-color, light);
            &:hover {
              color: darken(
                getThematicValue($theme-color, light),
                map-deep-get(
                  $button-themes,
                  $this-theme,
                  "lightAdjustmentRatio"
                )
              );
            }
            &:active {
              color: darken(
                getThematicValue($theme-color, light),
                map-deep-get(
                    $button-themes,
                    $this-theme,
                    "lightAdjustmentRatio"
                  ) * 2
              );
            }
          }
        }
        @include darkMode() {
          background-color: getThematicValue($theme-color, dark);
          color: chooseContrastingColor(getThematicValue($theme-color, dark));
          &:hover {
            background-color: darken(
              getThematicValue($theme-color, dark),
              map-deep-get($button-themes, $this-theme, "darkAdjustmentRatio")
            );
          }
          &:active {
            background-color: darken(
              getThematicValue($theme-color, dark),
              map-deep-get($button-themes, $this-theme, "darkAdjustmentRatio") *
                2
            );
          }
          &.ui-button__type--icon {
            background-color: transparent;
            color: getThematicValue($theme-color, dark);
            &:hover {
              color: darken(
                getThematicValue($theme-color, dark),
                map-deep-get($button-themes, $this-theme, "darkAdjustmentRatio")
              );
            }
            &:active {
              color: darken(
                getThematicValue($theme-color, dark),
                map-deep-get($button-themes, $this-theme, "darkAdjustmentRatio") *
                  2
              );
            }
          }
        }
      }
    }
  }

  @include element("size") {
    @include modifier("") {
      @include bindElementModifier("size", "md");
    }
    @include modifier("xxl") {
      font-size: $TEXT__H2--FONT-SIZE;
      border-radius: calc(1em / 4);
    }
    @include modifier("xl") {
      font-size: $TEXT__H3--FONT-SIZE;
      border-radius: calc(1em / 4);
    }
    @include modifier("lg") {
      font-size: $TEXT__H4--FONT-SIZE;
      border-radius: calc(1em / 4);
    }
    @include modifier("md") {
      font-size: $COPY_TEXT--FONT_SIZE;
      border-radius: calc(1em / 4);
    }
    @include modifier("sm") {
      font-size: $TEXT__H7--FONT-SIZE;
      border-radius: calc(1em / 4);
    }
    @include modifier("xs") {
      font-size: $TEXT__H8--FONT-SIZE;
      border-radius: calc(1em / 4);
    }
  }

  @include element("type") {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.6rem 1.6rem;
    @include modifier("icon") {
      padding: 0 0;
      min-width: 1.75rem;
      min-height: 1.75rem;
      font-size: 1.75rem;
      // text-align: end;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }

      min-width: 2.75rem;
      min-height: 2.75rem;

      @media (pointer: coarse) {
        min-width: 2.75rem;
        min-height: 2.75rem;
      }

      + .ui-button {
        // We do this to ensure buttons side-by-side do not touch
        // a little funky math here, but taking one half of the border radius, and making it the default
        // gives us a little extra optical positioning. This is done because rounded objects should *slightly*
        // collapse towards each other.
        margin-left: 0rem;
      }
    }
  }

  @include element("hidden-panel") {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0.49056603773584906em;
    pointer-events: none;
    transition: margin 0.4s ease;
    @include modifier("") {
      @include bindElementModifier("hidden-panel", "fromBottom");
    }
    @include modifier("undefined") {
      @include bindElementModifier("hidden-panel", "fromBottom");
    }
    @include modifier("fromTop") {
      margin-top: -2.5em;
    }
    @include modifier("fromBottom") {
      margin-top: 2.5em;
    }
    @include modifier("fromLeft") {
      margin-left: -100%;
    }
    @include modifier("fromRight") {
      margin-left: 100%;
    }
    @include modifier("neutralState") {
      margin-top: 0;
      margin-left: 0;
    }
  }
}
